import {
  DATE_FORMAT_FROM_INPUT,
  SimpleDatePicker,
  SimpleDatePickerProps,
  TIME_FORMAT_FROM_INPUT
} from '@hconnect/uikit/src/lib2/components/momentDatePicker'
import {getDateObjectFromInput} from '@hconnect/uikit/src/lib2/components/momentDatePicker/helpers'
import {Grid, TextField} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getHoursAndMinutesFromTimeString} from '../../utils'

function getTimeStringFromDate(d: Moment | undefined | null) {
  if (!d) return undefined
  return d.format(TIME_FORMAT_FROM_INPUT)
}

interface SimpleDateTimePickerProps extends SimpleDatePickerProps {
  variant?: 'standard' | 'filled' | 'outlined'
  showClearButton?: boolean
}

export const SimpleDateTimePicker: React.FC<SimpleDateTimePickerProps> = ({
  date,
  handleDateChange,
  variant,
  showClearButton,
  ...restProps
}) => {
  const timeString = getTimeStringFromDate(date)

  const handleDateChangeInternal = React.useCallback(
    (d: Moment | null) => {
      // apply time to currently set date
      const dd = d?.clone() || null
      if (timeString && dd) {
        handleDateChange?.(
          getDateObjectFromInput(dd?.format(DATE_FORMAT_FROM_INPUT), timeString, restProps.timezone)
        )
      }
      handleDateChange?.(d)
    },
    [timeString, handleDateChange, restProps.timezone]
  )
  const handleTimeChange = React.useCallback(
    (time: string) => {
      // everytime user enters something in the time field, bubble a date change event
      // where we set the entered time into the date field
      if (date) {
        const [hour, minute] = getHoursAndMinutesFromTimeString(time)
        date.set({hour, minute})
        handleDateChange?.(date)
      }
    },
    [handleDateChange, date]
  )

  const textFormatter = React.useCallback(() => (date ? date.format('L LT') : ''), [date])

  const TimeCalendarWrapper = (innerPicker: React.ReactNode) => {
    const {t} = useTranslation()

    return (
      <div>
        {innerPicker}
        <Grid container style={{paddingTop: '1em'}}>
          <Grid item xs={12}>
            <TextField
              label={t('datePicker.time')}
              type="time"
              data-test-id="simple-date-time-picker-time"
              value={timeString}
              onChange={(ev) => handleTimeChange(ev.target.value)}
              style={{width: '100%'}}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 300 // 5 min
              }}
              variant={variant}
            />
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <div data-test-id="simple-date-time-picker">
      <SimpleDatePicker
        {...restProps}
        handleDateChange={handleDateChangeInternal}
        date={date}
        noAutoClose={true}
        textFormatter={textFormatter}
        calendarWrapper={TimeCalendarWrapper}
        fullWidth
        showClearButton={showClearButton}
      />
    </div>
  )
}
