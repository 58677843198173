import {Box} from '@mui/material'
import {isString} from 'lodash'
import moment, {Moment} from 'moment-timezone'
import React, {useMemo} from 'react'

import {filterSxStyles} from '../../styles'
import {DateRange, Shift} from '../../types'
import {getQuickSelectFilter, QuickSelectSlots, QUICK_SELECT_SLOTS} from '../../utils'

import {DatePicker} from './DatePicker'
type DatePickerProps = {
  onChange: (value: QuickSelectSlots | DateRange) => void
  value: QuickSelectSlots | DateRange | undefined
  shifts: Shift[]
  timezone: string
  plantNow: Moment
  defaultTimeRange: QuickSelectSlots | undefined
  translationPrefix?: string
  quickSelectSlots?: QuickSelectSlots[]
}

type DateRangeArray = [Moment, Moment]

export const FilterDatePicker: React.FC<DatePickerProps> = (props) => {
  const {shifts, plantNow, defaultTimeRange, timezone, translationPrefix, quickSelectSlots} = props

  // Default quick select option is currentShift
  const {onChange, value: selectedOption = defaultTimeRange} = props

  const state = useMemo(() => {
    let date: Moment | undefined
    let quickSelectId: QuickSelectSlots | undefined
    let dateRange: DateRangeArray | undefined
    if (selectedOption && !isString(selectedOption)) {
      // Date or a date range is selected
      const {startDate, endDate}: DateRange = selectedOption
      if (startDate && endDate) {
        // In case of whole day selection, starting time is picked to calculate date
        const diff = moment.duration(endDate.diff(startDate)).asHours()
        if (diff >= 24) {
          dateRange = [startDate, endDate]
        } else {
          date = startDate
        }
      }
    } else {
      // Quick select option is selected
      quickSelectId = selectedOption

      // If applied quick select filter key does not exist, fall back to last 30 days
      if (!quickSelectId || !QUICK_SELECT_SLOTS.includes(quickSelectId)) {
        quickSelectId = 'last30days'
      }

      // calculate quick select corresponding date for calender
      const [, end] = getQuickSelectFilter(quickSelectId, shifts, plantNow)
      // subtracted 1 min to always fall in the shift duration
      date = moment(end).clone().subtract(1, 'minute')
    }

    return {
      quickSelectId,
      date,
      dateRange
    }
  }, [selectedOption, shifts, plantNow])

  const {quickSelectId, date, dateRange} = state

  return (
    <Box sx={filterSxStyles.dateFilter} data-test-id="simple-shift-picker">
      <DatePicker
        quickSelectId={quickSelectId}
        date={date}
        dateRange={dateRange}
        onChange={onChange}
        timezone={timezone}
        shifts={shifts}
        plantNow={plantNow}
        translationPrefix={translationPrefix}
        quickSelectSlots={quickSelectSlots}
      />
    </Box>
  )
}
