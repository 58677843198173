import {isString} from 'lodash'
import moment, {Moment} from 'moment-timezone'

import {DateRange, ParticularShift, Shift} from '../types'

import {timezonedDateValidator, URL_DATE_FORMAT} from './datetime.utils'
import {
  getShift,
  getShiftsForDay,
  alignToShiftEnd,
  alignToShiftStart,
  getShiftBounds,
  getTimeRangeForShift,
  sortDateShiftsOnStartTime
} from './shift.utils'

export const QUICK_SELECT_SLOTS = [
  'currentShift',
  'pastShift',
  'today',
  'last24hours',
  'yesterday',
  'lastWeekend',
  'last3days',
  'last7days',
  'last30days',
  'next30days',
  'rolling12months'
] as const

export type QuickSelectSlots = (typeof QUICK_SELECT_SLOTS)[number]

export const QUICK_SELECT_SLOTS_DEFAULT: QuickSelectSlots[] = [
  'currentShift',
  'pastShift',
  'today',
  'last24hours',
  'yesterday',
  'lastWeekend',
  'last3days',
  'last7days',
  'last30days',
  'next30days',
  'rolling12months'
]

function getCurrentShiftTiming(now: Moment, shifts: Shift[]): [start: Moment, end: Moment] {
  const currentShift = getShift(now, shifts)
  const endDate = currentShift.endDate
  return [currentShift.startDate, endDate]
}

function getPastShiftTiming(now: Moment, shifts: Shift[]): [start: Moment, end: Moment] {
  const currentShift = getShift(now, shifts)
  if (!currentShift) throw new Error('unable to find current shift')

  const currentShiftStartTime = currentShift.startDate.clone().add(1, 'second')

  const todayShifts: ParticularShift[] = sortDateShiftsOnStartTime(currentShiftStartTime, shifts)
  let pastShift: Shift | null = null

  // Alternative: instead check if current time is between todayShifts[0] startDate and endDate
  if (
    todayShifts[0].startDate.isSame(currentShift.startDate) &&
    todayShifts[0].endDate.isSame(currentShift.endDate)
  ) {
    // desired past shift for the first shift of the day is the last shift of yesterday
    const yesterday = currentShiftStartTime.clone().subtract(1, 'day')
    const yesterdayShifts = sortDateShiftsOnStartTime(yesterday, shifts)
    pastShift = yesterdayShifts[yesterdayShifts.length - 1]
  } else {
    for (let i = 1; i < todayShifts.length; i++) {
      const {startDate, endDate} = todayShifts[i]
      const startCheck = startDate.isSame(currentShift.startDate),
        endCheck = endDate.isSame(currentShift.endDate)
      if (startCheck && endCheck) {
        pastShift = todayShifts[i - 1]
        break
      }
    }
  }

  const {startDate, endDate} = getTimeRangeForShift(currentShiftStartTime, pastShift as Shift)
  return [startDate, endDate]
}

function getTodayTiming(now: Moment): [start: Moment, end: Moment] {
  const today = now.clone().startOf('day')
  return [today, now.startOf('minute')]
}

function getLast24hoursTiming(now: Moment, shifts: Shift[]): [start: Moment, end: Moment] {
  const currentShift = getShift(now, shifts)
  const endDate = currentShift.endDate
  return [endDate.clone().subtract(24, 'hours'), endDate]
}

function getYesterdayShiftTiming(now: Moment, shifts: Shift[]): [start: Moment, end: Moment] {
  const yesterday = now.clone().subtract(1, 'day')
  const yesterdaysShifts = getShiftsForDay(yesterday, shifts)
  const sortedShifts = yesterdaysShifts.sort((a, b) => (a.start < b.start ? 0 : 1))
  const start = alignToShiftStart(yesterday, sortedShifts[0])
  const end = alignToShiftEnd(yesterday, sortedShifts[sortedShifts.length - 1])
  return [start, end]
}

function getLastWeekendShiftTimings(now: Moment, shifts: Shift[]): [start: Moment, end: Moment] {
  const sun: Moment = now.clone().subtract(now.day(), 'day')
  const fri: Moment = sun.clone().subtract(2, 'day')
  const sunShifts = getShiftsForDay(sun, shifts)
  const sortedSunShifts = sunShifts.sort((a, b) => (a.start < b.start ? 0 : 1))
  const friShifts = getShiftsForDay(fri, shifts)
  const sortedFriShifts = friShifts.sort((a, b) => (a.start < b.start ? 0 : 1))

  const start = alignToShiftStart(fri, sortedFriShifts[sortedFriShifts.length - 1])
  const end = alignToShiftEnd(sun, sortedSunShifts[sortedSunShifts.length - 1])
  return [start, end]
}

function getLast3days(now: Moment, shifts: Shift[]): [start: Moment, end: Moment] {
  const last3rdDay: Moment = now.clone().subtract(3, 'day')
  return getShiftBounds(shifts, last3rdDay, now, true)
}

function getLast7days(now: Moment, shifts: Shift[]): [start: Moment, end: Moment] {
  const last7thDay: Moment = now.clone().subtract(7, 'day')
  return getShiftBounds(shifts, last7thDay, now, true)
}

function getLast30days(now: Moment, shifts: Shift[]): [start: Moment, end: Moment] {
  const last30thDay: Moment = now.clone().subtract(30, 'day')
  return getShiftBounds(shifts, last30thDay, now, true)
}

function getNext30days(now: Moment, shifts: Shift[]): [start: Moment, end: Moment] {
  const next30days: Moment = now.clone().add(30, 'day')
  return getShiftBounds(shifts, now, next30days)
}

function getRolling12months(now: Moment, shifts: Shift[]): [start: Moment, end: Moment] {
  const last12Months = now.clone().subtract(12, 'month')
  return getShiftBounds(shifts, last12Months, now, true)
}

export function getQuickSelectFilter(
  slot: QuickSelectSlots,
  shifts: Shift[],
  now: Moment
): [start: Moment, end: Moment] {
  let dates: [start: Moment, end: Moment]

  switch (slot) {
    case 'currentShift':
      dates = getCurrentShiftTiming(now, shifts)
      break
    case 'pastShift':
      dates = getPastShiftTiming(now, shifts)
      break
    case 'today':
      dates = getTodayTiming(now)
      break
    case 'last24hours':
      dates = getLast24hoursTiming(now, shifts)
      break
    case 'yesterday':
      dates = getYesterdayShiftTiming(now, shifts)
      break
    case 'lastWeekend':
      dates = getLastWeekendShiftTimings(now, shifts)
      break
    case 'last3days':
      dates = getLast3days(now, shifts)
      break
    case 'last7days':
      dates = getLast7days(now, shifts)
      break
    case 'last30days':
      dates = getLast30days(now, shifts)
      break
    case 'next30days':
      dates = getNext30days(now, shifts)
      break
    case 'rolling12months':
      dates = getRolling12months(now, shifts)
      break
    default:
      throw new Error(`Unknown predefined time slot: ${slot}`)
  }
  return dates
}

export const generateTimeRangeFromUrlParam = (utcTimeRange: string, timezone: string) => {
  let result
  if (QUICK_SELECT_SLOTS.includes(utcTimeRange as QuickSelectSlots)) {
    result = utcTimeRange as QuickSelectSlots
  } else {
    const [startDate, endDate] = utcTimeRange.split(',')
    result = {
      startDate: moment.utc(startDate, URL_DATE_FORMAT).tz(timezone),
      endDate: moment.utc(endDate, URL_DATE_FORMAT).tz(timezone)
    }
  }
  return result
}

export const getTimeRangeOrQuickSelectSlotsString = (
  timeRange: DateRange | QuickSelectSlots
): string => {
  if (isString(timeRange)) {
    return timeRange
  } else {
    const {startDate, endDate} = timeRange
    if (!startDate || !endDate)
      throw new Error('if either of startDate or endDate is given, both must be set')
    return getTimeRangeString(startDate.clone().utc(), endDate.clone().utc())
  }
}

export const getTimeRangeString = (startDateUtc: Moment, endDateUtc: Moment) => {
  timezonedDateValidator(startDateUtc, endDateUtc)
  return `${startDateUtc.format(URL_DATE_FORMAT)},${endDateUtc.format(URL_DATE_FORMAT)}`
}

export const generateQueryString = (queryObject: Record<string, string>) => {
  const queryParams = new URLSearchParams()
  for (const key in queryObject) {
    if (queryObject[key] !== undefined) {
      queryParams.append(key, queryObject[key])
    }
  }
  return queryParams.toString()
}
