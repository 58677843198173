import {useMobileBreakPoint, useNotification} from '@hconnect/uikit'
import QrCodeIcon from '@mui/icons-material/QrCode'
import {Box} from '@mui/material'
import {AxiosError} from 'axios'
import {isUndefined} from 'lodash'
import React, {ReactNode, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useEquipment} from '../../hooks/useEquipment'
import {EquipmentData, HttpError, Stoppage, EquipmentPlain, EquipmentIdType} from '../../types'
import {BarcodeScannerPopUp} from '../barScanner/BarcodeScannerPopUp'
import {
  MainEquipmentSearchHierarchy,
  EquipmentSearchHierarchy,
  FormIconButton
} from '../shiftEventFormFields'

const EQUIPMENT_CONTAINER_SX = {
  display: 'flex',
  '&:not(:last-child)': {
    paddingBottom: 1
  },
  '&:last-child': {
    paddingTop: 1
  },
  gap: 2
}

type EquipmentSearchContainerProps = {
  label?: ReactNode
  errorText?: ReactNode
  updateMultiPart: (delta: Partial<Stoppage>) => void
  plantId: string
  required?: boolean
  value: Stoppage
  disabled?: boolean
  translationPrefix?: string
  equipmentIdType: EquipmentIdType
}

export const EquipmentSearchContainer = (props: EquipmentSearchContainerProps) => {
  const {i18n} = useTranslation()
  const {notify} = useNotification()
  const [isScannerOpen, setIsScannerOpen] = useState(false)
  const isMobile = useMobileBreakPoint()

  const {updateMultiPart, errorText, value: item, required, disabled} = props

  const [equipment, setEquipment] = useState<EquipmentPlain | undefined>(
    item.equipment
      ? {
          equipment: item.equipment,
          path: []
        }
      : undefined
  )
  const [mainEquipment, setMainEquipment] = useState<EquipmentData | undefined>(
    item.mainEquipment
      ? {
          id: item.mainEquipment.id,
          idType: item.mainEquipment.idType,
          text: item.mainEquipment.text
        }
      : undefined
  )

  const updateMainEquipment = useCallback(
    (value?: EquipmentData) => {
      setMainEquipment(value)
      updateMultiPart({
        mainEquipment: value
      })
    },
    [updateMultiPart]
  )

  const updateEquipment = useCallback(
    (value?: EquipmentPlain) => {
      setEquipment(value)
      const mainEquipmentData = value?.mainEquipment
      if (!isUndefined(mainEquipmentData)) {
        updateMultiPart({
          equipment: value?.equipment,
          processStage: value?.processStage?.code,
          mainEquipment: mainEquipmentData
        })
        setMainEquipment(mainEquipmentData)
      } else {
        updateMultiPart({
          equipment: value?.equipment,
          processStage: value?.processStage?.code
        })
      }
    },
    [updateMultiPart]
  )

  const [searchTerm, setSearchTerm] = useState<string | undefined>()
  useEquipment(props.plantId, searchTerm || '', 'sapNumber', i18n.language, {
    enabled: !!searchTerm,
    onSuccess: (item) => {
      if (item) {
        updateEquipment(item)
        setSearchTerm(undefined)
      }
    },
    onError: (error: AxiosError<HttpError>) => {
      if (error.response?.data.detail) {
        notify('error', error.response.data.detail)
        setSearchTerm(undefined)
      }
    }
  })

  return (
    <>
      <Box sx={EQUIPMENT_CONTAINER_SX}>
        <Box flex={1}>
          <EquipmentSearchHierarchy
            data-test-id="equipment-search-dropdown"
            errorText={errorText}
            onChange={(value) => updateEquipment(value)}
            value={equipment}
            required={required}
            disabled={disabled}
            plantId={props.plantId}
            mainEquipmentId={mainEquipment?.id}
            translationPrefix={props.translationPrefix}
            idType={props.equipmentIdType}
          />
        </Box>
        {isMobile && (
          <>
            <FormIconButton
              data-test-id="equipment-search-container-scan-button"
              onClick={() => setIsScannerOpen(!isScannerOpen)}
              disabled={disabled}
            >
              <QrCodeIcon />
            </FormIconButton>

            <BarcodeScannerPopUp
              open={isScannerOpen}
              onClose={() => setIsScannerOpen(false)}
              onScan={setSearchTerm}
            />
          </>
        )}
      </Box>
      <Box sx={EQUIPMENT_CONTAINER_SX}>
        <Box flex={1}>
          <MainEquipmentSearchHierarchy
            plantId={props.plantId}
            onChange={(value) => updateMainEquipment(value)}
            value={mainEquipment}
            required={false}
            disabled={disabled}
            translationPrefix={props.translationPrefix}
            idType={props.equipmentIdType}
          />
        </Box>
      </Box>
    </>
  )
}
