import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {Theme, SxProps} from '@mui/material'

const label = {
  color: '#ffffff',
  opacity: '0.5'
}

export const getButtonOutlineWhite = (theme: Theme) => ({
  border: 1,
  borderColor: customThemeConstants().palette.mediumInputBorder,
  color: theme.palette.common.white,
  padding: 2
})

const getFilterBarCss = (theme) => ({
  '& .MuiFormControl-root': {
    m: 1
  },
  '& .MuiFilledInput-adornedEnd': {
    paddingTop: '12px!important'
  },
  '& .MuiFilledInput-root': {
    border: 1,
    borderColor: customThemeConstants().palette.mediumInputBorder,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.common.white
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent'
    }
  },
  '& .MuiFilledInput-input': {
    color: '#ffffff',
    padding: '20px 12px 10px'
  },
  '& .MuiFormLabel-root': {
    fontSize: '14px'
  },
  '& .MuiSelect-icon': {
    color: '#ffffff'
  },
  '& .MuiInputLabel-filled': label,
  '& .MuiIconButton-root': {
    backgroundColor: '#08425a',
    color: '#FFFFFF',
    padding: '4px',
    '&:hover': {
      backgroundColor: '#08425a',
      fontSize: '1rem'
    }
  }
})

export const filterSxStyles: Record<string, SxProps<Theme>> = {
  filterBar: (theme) => ({...getFilterBarCss(theme)}),
  filterLayover: (theme) => ({...getFilterBarCss(theme), marginTop: theme.spacing(-4)}),
  filterButton: {
    display: 'inline-block',
    float: 'right'
  },
  filterSearchButton: (theme) => ({...getButtonOutlineWhite(theme), m: 1}),
  dateFilter: (theme) => ({
    margin: 1,
    '& .MuiButtonBase-root': {
      padding: '2px 10px',
      background: 'transparent',
      border: 1,
      borderColor: customThemeConstants().palette.mediumInputBorder,
      '&:hover': {
        borderColor: theme.palette.common.white
      }
    },
    '& .MuiTypography-root': {
      color: theme.palette.common.white
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.common.white
    },
    '& .MuiTypography-caption': label
  }),
  textInputField: (theme) => ({
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    width: {
      md: 200
    },
    '& .MuiOutlinedInput-root': {
      color: theme.palette.common.white,
      backgroundColor: 'transparent'
    },
    '& fieldset': {
      borderColor: customThemeConstants().palette.mediumInputBorder
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.white
    },
    '& .Mui-focused.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.light
    },
    '& .MuiInputLabel-root': {
      color: customThemeConstants().palette.mediumInputBorder
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: theme.palette.common.white
    }
  })
}
