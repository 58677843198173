import {mergeSxs} from '@hconnect/uikit/src/lib2'
import {TextField, MenuItem, Box} from '@mui/material'
import React, {useMemo} from 'react'

import {LocationSelectOption} from '../../hproduce/types'
import {selectFieldOnBlueSx} from '../../styles'

type Props = {
  options: LocationSelectOption[]
  label: string
  dataTestId?: string
}

export const LocationSelectDropdown = ({label, options, dataTestId}: Props) => {
  const sortedOptions = useMemo(
    () => options?.sort((a, b) => (a.name > b.name ? 1 : -1)),
    [options]
  )

  return (
    <TextField
      variant="filled"
      sx={mergeSxs(selectFieldOnBlueSx, {width: '100%'})}
      label={label}
      select
      data-test-id={dataTestId}
    >
      {sortedOptions.map((option) => (
        <Box
          component="a"
          key={option.id}
          href={option.url}
          sx={{
            color: ({palette}) => palette.text.primary,
            textDecoration: 'none'
          }}
          data-test-id={`${dataTestId}-option-${option.id}`}
        >
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        </Box>
      ))}
    </TextField>
  )
}
