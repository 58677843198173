// RCFA Routes
export const GRAPH_DETAIL_ROUTE = '/plant/:plantId/rcfa/graphDetail/:analysisId/:type'
export const PLANT_ANALYSIS_LIST_ROUTE = '/plant/:plantId/rcfa/list'
export const PLANT_STOPPAGE_LIST_ROUTE = '/plant/:plantId/rcfa/stoppages'

// KMS Routes
export const PLANT_KMS_ROUTE = '/plant/:plantId/kms/:kilnId'

// Downtime Dashboard
export const PLANT_DOWNTIME = '/downtime/:plantId'
