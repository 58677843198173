import {Box, Typography, Stack} from '@mui/material'
import React from 'react'

import {LocationSelectOption} from '../../hproduce/types'

import SelectPlant from './assets/SelectPlant.svg'
import {LocationSelectDropdown} from './LocationSelectDropdown'

type Props = {
  options: LocationSelectOption[]
  selectALocationLabel: string
  description: string
  selectDropdownLabel: string
  dataTestId?: string
}

export const LocationSelect: React.FC<Props> = ({
  options,
  selectALocationLabel,
  description,
  selectDropdownLabel,
  dataTestId = 'location-select'
}) => {
  return (
    <Stack alignItems="center" data-test-id={dataTestId}>
      <Box
        component="img"
        src={SelectPlant}
        alt="Select-Plant-Img"
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            width: '373px'
          }
        })}
      />
      <Stack spacing={4} alignItems="center" sx={{width: '335px'}}>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h1" data-test-id={`${dataTestId}-label`}>
            {selectALocationLabel}
          </Typography>
          <Typography data-test-id={`${dataTestId}-description`} sx={{opacity: 0.6}}>
            {description}
          </Typography>
        </Stack>
        <LocationSelectDropdown
          options={options}
          label={selectDropdownLabel}
          dataTestId={`${dataTestId}-dropdown`}
        />
      </Stack>
    </Stack>
  )
}
