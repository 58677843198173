import {EquipmentIdType} from '../types'

import {useEquipmentNumberSearchQuery} from './useEquipmentNumberSearchQuery'
import {useMainEquipmentNumberSearchQuery} from './useMainEquipmentNumberSearchQuery'

export const useMainEquipmentFilterOptionsSearch = (
  plantId: string,
  searchQuery?: string,
  idType?: EquipmentIdType
) => {
  // TODO: check if this is okay to move to isFetching
  const {data: mainEquipments = [], isFetching: isFetchingMainEquipments} =
    useMainEquipmentNumberSearchQuery({searchTerm: searchQuery, plantId: plantId})
  const {data: additionalEquipments = [], isFetching: isFetchingAdditional} =
    useEquipmentNumberSearchQuery({searchTerm: searchQuery, plantId, idType})
  const sapMainEquipment = additionalEquipments
    .filter((e) => Array.isArray(e.path) && e.path.length === 0)
    .map((item) => item.equipment)

  const options = mainEquipments.length > 0 ? mainEquipments : sapMainEquipment
  const isFetching = isFetchingMainEquipments || isFetchingAdditional

  return {data: options, isFetching}
}
