import {Autocomplete, Paper, TextField} from '@mui/material'
import React, {FC, ReactNode, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {INPUT_FORM_FIELD_SX} from '../../consts'
import {useProcessStages} from '../../hooks/useProcessStages'
import {ProcessStage} from '../../types'
import {getTranslationKey} from '../../utils'

type ProcessStageAutocompleteProps = {
  plantId: string
  label?: ReactNode
  errorText?: ReactNode
  onChange: (processStage: string | undefined) => void
  value?: string
  required?: boolean
  disabled?: boolean
  translationPrefix?: string
}

const paper = (props) => <Paper elevation={8} style={{minWidth: '350px'}} {...props} />
const getProcessStageLabel = (stage: ProcessStage) => `${stage.code} - ${stage.name}`
const areProcessStagesSame = (a: ProcessStage, b: ProcessStage) => a.code === b.code

export const ProcessStageAutocomplete: FC<ProcessStageAutocompleteProps> = ({
  onChange = () => undefined,
  value,
  plantId,
  ...props
}) => {
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState<string>('')
  const {data: stages = []} = useProcessStages<ProcessStage[]>(plantId, {
    select: (value) => Array.from(value, ([code, name]) => ({code, name}))
  })
  const selectedProcessStage = stages.find((stage) => stage.code === value)
  const options = stages
    ? stages.filter(
        (stage) =>
          stage.code !== selectedProcessStage?.code &&
          getProcessStageLabel(stage).toLowerCase().includes(inputValue.trim().toLowerCase())
      )
    : []

  useEffect(() => {
    if (selectedProcessStage) {
      setInputValue(selectedProcessStage.name)
    }
  }, [selectedProcessStage, setInputValue])

  return (
    <Autocomplete
      PaperComponent={paper}
      forcePopupIcon={false}
      value={selectedProcessStage ?? null}
      inputValue={inputValue}
      open={open && stages.length > 0}
      onChange={(event, next, reason) => {
        if (reason === 'clear') {
          setInputValue('')
          onChange(undefined)
          return
        }
        if (next && typeof next !== 'string') {
          onChange(next.code)
        }
      }}
      onInputChange={(event, newValue, reason) => {
        if (reason === 'input' && newValue !== inputValue) {
          setInputValue(newValue)

          if (!newValue && value) {
            onChange(undefined)
          }
        }
      }}
      getOptionLabel={getProcessStageLabel}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={!!props.errorText}
            data-test-id="process-stage-search-input"
            helperText={props.errorText}
            label={
              t(getTranslationKey('shiftEvent.label.processStage', props.translationPrefix)) +
              (!props.required
                ? ` (${t(getTranslationKey('shiftEvent.label.optional', props.translationPrefix))})`
                : '')
            }
            required={props.required}
            placeholder={t(getTranslationKey('pleaseStartTyping', props.translationPrefix))}
            variant="filled"
            InputProps={{...params.InputProps, ...INPUT_FORM_FIELD_SX}}
          />
        )
      }}
      isOptionEqualToValue={areProcessStagesSame}
      options={options}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      sx={{
        marginRight: {xs: 2, md: 0}
      }}
      renderOption={(props, option) => (
        <li {...props} data-test-id={`process-stage-search-suggestion-${option.code}`}>
          {option.name}
        </li>
      )}
      filterOptions={(options) => options}
    />
  )
}
