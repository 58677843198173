import {formatDateLocale} from '@hconnect/uikit'
import {Box} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useMemo} from 'react'

import {CommonDropDown} from '../../components/shiftEventFormFields'
import {ParticularShift, Shift} from '../../types'
import {getShiftsForDay, getShift} from '../../utils'

export interface ShiftDropdownProps {
  date: Moment | null
  className?: string
  label?: React.ReactNode
  onChange: (particularShift: ParticularShift) => void
  keyName?: string
  shifts: Shift[]
  translationPrefix?: string
}

type DateChangeOptions = {
  shifts: Shift[]
}

type ShiftPickOptions = {
  shifts: Shift[]
}

export const handleDateChange = (localNext: Moment, options: DateChangeOptions) => {
  const {shifts} = options

  const syncedPlantNext = localNext.clone()

  let particularShift = getShift(syncedPlantNext, shifts)

  if (!particularShift.startDate.isSame(syncedPlantNext, 'day')) {
    // special case with day roll over
    particularShift = getShift(syncedPlantNext.add(1, 'day'), shifts)
  }

  return particularShift
}

export const shiftPickLabel = (date: Moment, options: ShiftPickOptions) => {
  const {shifts} = options
  const selectedShift = getShift(date, shifts)
  const dateLabel = formatDateLocale(selectedShift.startDate)
  return `${dateLabel} - ${selectedShift?.name}`
}

export const momentShiftPickLabel = (date: Moment, options: ShiftPickOptions) => {
  const {shifts} = options
  const selectedShift = getShift(date, shifts)
  const dateLabel = formatDateLocale(selectedShift.startDate)
  return `${dateLabel} - ${selectedShift?.name}`
}

export const getShiftRange = (pickedShift: ParticularShift) => {
  return {
    startDate: pickedShift.startDate,
    endDate: pickedShift.endDate
  }
}

export const ShiftDropdown: React.FC<ShiftDropdownProps> = (props) => {
  const {date: inputDate, keyName, shifts, translationPrefix} = props

  if (!inputDate) {
    throw new Error('missing localDate prop in ShiftPicker')
  }

  const state = useMemo(() => {
    const shiftsForThatDay = getShiftsForDay(inputDate, shifts)
    const selectedShift = getShift(inputDate, shifts)
    const localStartTime = selectedShift.startDate

    if (!shiftsForThatDay.find((shift) => shift.startDate.isSame(selectedShift?.startDate))) {
      // just a safety net as it should not happen but if it does it will result in some wired error in the CommonDropDown
      throw new Error('selectedShift is not in the list of shiftsForThatDay')
    }

    const shiftPickLabel = (shiftId: string) => {
      const shift = state.shiftsForThatDay[state.selectOptions.indexOf(shiftId)]
      return `${shift.name} ${shift.start.substring(0, 5)}-${shift.end.substring(0, 5)}`
    }

    return {
      inputDate,
      localStartTime,
      shiftsForThatDay,
      selectedShift,
      selectOptions: shiftsForThatDay.map((s) => s.startDate.toISOString()),
      shiftPickLabel
    }
  }, [inputDate, shifts])
  return (
    <Box>
      <CommonDropDown
        keyName={keyName || 'shift'}
        data-test-id="shift-picker"
        fullWidth={true}
        onChange={(utcStartDate) => {
          const particularShift = state.shiftsForThatDay.find(
            (shift) => shift.startDate.toISOString() === utcStartDate
          )
          particularShift && props.onChange(particularShift)
        }}
        required
        options={state.selectOptions}
        customTemplate={state.shiftPickLabel}
        value={state.selectedShift.startDate.toISOString()}
        translationPrefix={translationPrefix}
      />
    </Box>
  )
}
