/**
 * Enumeration of analysis types to differentiate business cases related to interactions with another applications, like Cockpit (ShiftHandover...) or so.
 * @export
 * @enum {string}
 */
export enum AnalysisType {
  Custom = 0,
  Cockpit = 1
}
export enum AnalysisStatus {
  Ongoing = 'Ongoing',
  Finished = 'Finished'
}
export enum AnalysisPrivacyStatus {
  Private = 'Private',
  Public = 'Public'
}
export enum AnalysisListType {
  Mine = 'mine',
  Contributing = 'contributing',
  Global = 'global',
  MyPlant = 'myplant'
}

export const GraphDetailScreens = ['graph', 'tasks', 'summary'] as const

export type GraphDetailScreen = (typeof GraphDetailScreens)[number]

export type RootCauseFailureAnalyses = {
  id: number
  title: string
  externalId: string
  plantId: string
  status: AnalysisStatus
  type: AnalysisType
  privacyStatus: AnalysisPrivacyStatus
  ownerId: string
  contributorIds: string[]
}
