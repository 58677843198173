import {isoWeekDays} from '@hconnect/uikit'
import moment, {Moment} from 'moment-timezone'

import {ParticularShift, Shift, DayTime, DateRange} from '../types'

import {timezonedDateValidator, getHoursAndMinutesFromTimeString} from './datetime.utils'

export function getShiftsForDay(localDate: Moment, shifts: Shift[]): ParticularShift[] {
  const {list} = getAllPossibleShiftsOfDay(localDate, shifts)
  return list
}

/*
 *
 *
 * Previous code that can get phased out (hopefully soon) ->
 *
 *
 */

export function alignToShiftEnd(date: Moment, shift: Shift): Moment {
  const newDate = date.clone()
  const [endHours, endMinutes] = getHoursAndMinutesFromTimeString(shift.end)
  if (timeIsSameOrBefore(shift.end, shift.start)) {
    // night shift!
    newDate.add(1, 'day')
  }
  return newDate
    .hours(endHours)
    .minutes(endMinutes)
    .seconds(0)
    .milliseconds(0)
    .subtract(1, 'millisecond')
}

export function alignToShiftStart(date: Moment, shift: Shift): Moment {
  const newDate = date.clone()
  const [startHours, startMinutes] = getHoursAndMinutesFromTimeString(shift.start)
  return newDate.hours(startHours).minutes(startMinutes).seconds(0).milliseconds(0)
}

export function getTimeRangeForShift(date: Moment, shift: Shift): DateRange {
  const newDate = moment(date).clone()
  // Note: assumption that whole day is covered with shifts
  // ^ this would change in future
  if (timeIsSameOrBefore(shift.end, shift.start)) {
    newDate.subtract(1, 'day')
  }
  const start = alignToShiftStart(newDate, shift)
  const end = alignToShiftEnd(newDate, shift)
  return {startDate: start, endDate: end}
}

/**
 * determines if a time if before another, where time is encoded as string in HH:mm:ss format
 */
export function timeIsSameOrBefore(a: string, b: string) {
  if (a.length !== 8 || b.length !== 8) {
    throw new Error('Invalid time input length passed')
  }
  return a.localeCompare(b, 'de') <= 0
}

/**
 * sorts the given shifts for a day based on the start time
 * @param date
 * @param shifts
 * @returns sorted array of shifts based on the start time
 */
export function sortDateShiftsOnStartTime(date: Moment, shifts: Shift[]): ParticularShift[] {
  const dateShifts = getShiftsForDay(date, shifts)
  return dateShifts.sort((a, b) => (a.start < b.start ? 0 : 1))
}

/**
 * getShiftBounds returns the start and end bounds based on shifts
 * uses first shift of the fromDate and last or current shift of the toDate
 * @param shifts
 * @param fromDate
 * @param toDate
 * @param toCurrentShift
 * @returns array with start bound and end bound values
 */
export function getShiftBounds(
  shifts: Shift[],
  fromDate: Moment,
  toDate: Moment,
  toCurrentShift?: boolean
): [start: Moment, end: Moment] {
  timezonedDateValidator(fromDate, toDate)
  // TODO: check why not just use getShift with fromDate directly
  const fromDateShifts = sortDateShiftsOnStartTime(fromDate, shifts)
  const startDate = fromDateShifts[0].startDate
  if (toCurrentShift) {
    const currentShift = getShift(toDate, shifts)
    const endDate = currentShift.endDate
    return [startDate, endDate]
  }
  const toDateShifts = sortDateShiftsOnStartTime(toDate, shifts)
  const endDate = toDateShifts[toDateShifts.length - 1].endDate
  return [startDate, endDate]
}

export function roundUpToTheNearestMinute(date: Moment): Moment {
  return date.second() || date.millisecond() ? date.add(1, 'minute').startOf('minute') : date
}

export function dayTime(timeString: DayTime) {
  const timeParts = /(\d{2}):(\d{2}):(\d{2})(\.(\d{3}))?/.exec(timeString)
  if (!timeParts) throw new Error('Invalid time input format passed')

  const [, hour, minute, second, , millisecond = 0] = timeParts.map(Number)
  return {hour, minute, second, millisecond: isNaN(millisecond) ? 0 : millisecond}
}
/**
 * technically the day starts at the start of the first shift,
 * until then its still the last shift of the previous day
 * so we will return the list of shifts, based on the start date of the shift the utcDate falls into
 * and not necessarily on the day of the given utcDate
 *
 * the parameter returnMatch & previousDayCheck are currently for internal use only
 */
export function getAllPossibleShiftsOfDay(
  localDate: Moment,
  shifts: Shift[],
  previousDayCheck?: boolean
): {match: ParticularShift[]; list: ParticularShift[]} {
  const dayIndex = localDate.isoWeekday()

  const match: ParticularShift[] = []
  const result = shifts.reduce<ParticularShift[]>((list, shift) => {
    const dayRollOver =
      shift.start.localeCompare(shift.end, 'de') > 0 && !shift.end.startsWith('00:00:00')

    if (shift.weekDays.some((wd) => isoWeekDays.indexOf(wd) + 1 === dayIndex)) {
      const start = localDate.clone().set(dayTime(shift.start))
      const end = localDate
        .clone()
        .set(dayTime(shift.endTechnical))
        .add(dayRollOver ? 1 : 0, 'day')
      const particularShift: ParticularShift = {
        ...shift,
        startDate: start,
        endDate: end
      }

      // [] includes both the start date and the end date as we use the technical end time which avoids overlap
      if (localDate.isBetween(start, end, undefined, '[]')) {
        match.push(particularShift)
      }

      list.push(particularShift)
    }
    return list
  }, [])

  if (match.length === 0) {
    // fix for bug HCP-18243
    // technically the day starts at the start of the first shift,
    // until then its still the last shift of the previous day
    // so if the given utc dose not fall in to todays shift's we check and return the previous days shifts

    if (previousDayCheck) {
      // prevent an endless loop
      throw new Error('did not find a suitable shift')
    }

    // WARNING this works only if all part of the day are covert by shifts
    // in case of "blank spots" this previousDayCheck might give miss leading results
    // returning the "nightshirt of last day"

    const endOfPreviousDay = localDate.clone().subtract(1, 'day').endOf('day')
    return getAllPossibleShiftsOfDay(endOfPreviousDay, shifts, true)
  }

  return {
    match,
    list: result
  }
}

export function getShift(localDate: Moment, allShifts: Shift[]) {
  const {match} = getAllPossibleShiftsOfDay(localDate, allShifts)
  // A match should always be returned, and if not an exception would be thrown
  if (match.length === 0) {
    throw new Error('fail to find a shift for the given date')
  }

  // if (match.length >= 1) {
  //   // TBD what to do here as this should never happen
  //   // add a logging here maybe?
  // }

  return match[0]
}
