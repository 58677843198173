import {Page} from '@hconnect/uikit/src/lib2'
import {Theme, SxProps} from '@mui/material'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'
import React, {useEffect} from 'react'

export const FLEX_PAGE_HEADER_MY = 2
export const FLEX_PAGE_HEADER_MX = 3

type Props = {
  title?: React.ReactNode
  headerActionContent?: React.ReactNode
  beforeHeaderContent?: React.ReactNode
  aboveTitle?: React.ReactNode
  subTitle?: React.ReactNode
  sxActionContent?: SxProps<Theme>
  nameHeaderSx?: SystemStyleObject<Theme>
  nameBodySx?: SystemStyleObject<Theme>
  useViewportHeight?: boolean
  titleId?: string
  appName?: string
  children?: React.ReactNode
}

export const FlexPage = (props: Props) => {
  const appName = props.appName || 'HProduce'
  useEffect(() => {
    if (!document) return
    if (!props.title || typeof props.title !== 'string') {
      document.title = appName
    } else {
      document.title = `${appName} - ${props.title}`
    }
  }, [props.title, appName])

  return (
    <Page
      boxed={false}
      sx={{
        p: 0,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        ...(props.useViewportHeight && {
          /**
           * The height of the header is 66px
           * sets the height of the container to cover the rest of the page
           */
          height: 'calc(100vh - 66px)'
        })
      }}
      nameHeaderSx={{
        my: FLEX_PAGE_HEADER_MY,
        mx: FLEX_PAGE_HEADER_MX,
        flexWrap: 'wrap',
        alignItems: {xs: 'stretch', sm: 'center'},
        ...props.nameHeaderSx
      }}
      nameBodySx={{
        pt: 0,
        pb: {xs: 1, sm: 3},
        px: {xs: 1, sm: 3},
        flexGrow: 1,
        overflow: 'hidden',
        display: 'flex',
        ...props.nameBodySx
      }}
      sxActionContent={props.sxActionContent}
      title={props.title}
      aboveTitle={props.aboveTitle}
      subTitle={props.subTitle}
      headerActionContent={props.headerActionContent}
      beforeHeaderContent={props.beforeHeaderContent}
      titleId={props.titleId}
      data-test-id="pageTitle"
    >
      {props.children}
    </Page>
  )
}
