import React from 'react'
import {useTranslation} from 'react-i18next'

import {PlantSelectOption} from '../../hproduce/types'
import {getTranslationKey} from '../../utils'

import {LocationSelect} from './LocationSelect'

type Props = {
  plants: PlantSelectOption[]
  translationPrefix?: string
}

export const PlantSelect: React.FC<Props> = ({plants, translationPrefix}) => {
  const {t} = useTranslation()

  return (
    <LocationSelect
      options={plants.map(({plantId, plantName, url}) => ({id: plantId, name: plantName, url}))}
      selectALocationLabel={t(getTranslationKey('plantSelection.selectAPlant', translationPrefix))}
      description={t(getTranslationKey('plantSelection.desc', translationPrefix))}
      selectDropdownLabel={t(getTranslationKey('plantSelection.selectPlant', translationPrefix))}
      dataTestId="plant-select"
    />
  )
}
