import {Box, Button, Typography} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import ErrorSvg from './error.svg'

const CONTENT_PADDING = 3

type AttachmentLoadingErrorProps = {
  onRetry: () => void
  onClose?: () => void
  translationPrefix?: string
}

export const AttachmentLoadingError: FC<AttachmentLoadingErrorProps> = ({
  onRetry,
  onClose,
  translationPrefix
}) => {
  const {t} = useTranslation()

  return (
    <>
      <Typography variant="h3" p={CONTENT_PADDING}>
        {t(`${translationPrefix}.error.attachment.load.heading`)}
      </Typography>
      <Box px={CONTENT_PADDING} pb={2}>
        <Box display="flex" justifyContent="center">
          <Box component="img" src={ErrorSvg} alt="File-Error-Img" sx={{mb: 0.5, maxWidth: 240}} />
        </Box>
        <Typography>{t(`${translationPrefix}.error.attachment.load.body`)}</Typography>
      </Box>
      <Box
        px={CONTENT_PADDING}
        pb={CONTENT_PADDING}
        pt={2}
        gap={1.5}
        display="flex"
        justifyContent="flex-end"
      >
        {onClose && (
          <Button variant="outlined" color="secondary" onClick={onClose} data-test-id="close-btn">
            {t(`${translationPrefix}.error.action.close`)}
          </Button>
        )}
        <Button onClick={onRetry} variant="contained" data-test-id="retry-btn">
          {t(`${translationPrefix}.error.action.tryAgain`)}
        </Button>
      </Box>
    </>
  )
}
