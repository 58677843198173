import {Autocomplete, Paper, TextField} from '@mui/material'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {INPUT_FORM_FIELD_SX} from '../../consts'
import {useDebouncedString} from '../../hooks/useDebouncedString'
import {useEquipmentByMainEquipment} from '../../hooks/useEquipmentByMainEquipment'
import {useEquipmentNumberSearchQuery} from '../../hooks/useEquipmentNumberSearchQuery'
import {EquipmentIdType, EquipmentPlain} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'
import {getEquipmentLabel} from '../shiftEventLabels'

import {EquipmentHierarchy} from './Equipment'

const paper = (props) => <Paper elevation={8} style={{minWidth: '350px'}} {...props} />

const emptyArray = []

function getDisplayPath(equipmentPlain: EquipmentPlain) {
  const equipment = equipmentPlain.equipment
  return (
    <span data-test-id={`equipment-search-suggestion-${equipment.id}`}>
      <EquipmentHierarchy equipmentPlain={equipmentPlain} withId={true} />
    </span>
  )
}

export function areEquipmentsSame(option1: EquipmentPlain, option2: EquipmentPlain) {
  return option1 && option2 && option1.equipment.id === option2.equipment.id
}

type Props = {
  errorText?: React.ReactNode
  onChange: (equipment: EquipmentPlain | undefined) => void
  plantId: string
  value: EquipmentPlain | undefined
  required?: boolean
  disabled?: boolean
  mainEquipmentId?: string
  translationPrefix?: string
  idType?: EquipmentIdType
}
export const EquipmentSearchHierarchy = (props: Props) => {
  const {t} = useTranslation()
  const [open, setOpen] = React.useState<boolean>(false)

  const [inputValue, setInputValue] = React.useState<string>('')
  const debouncedQuery = useDebouncedString(inputValue, 1000)

  const mainEquipmentId = props.mainEquipmentId
  const searchQuery = useEquipmentNumberSearchQuery(
    {
      searchTerm: debouncedQuery,
      plantId: props.plantId,
      idType: props.idType
    },
    {
      enabled: !mainEquipmentId
    }
  )
  const searchMainEquipmentBasedEquipmentQuery = useEquipmentByMainEquipment(
    props.plantId,
    debouncedQuery,
    mainEquipmentId,
    {
      enabled: !!mainEquipmentId
    }
  )

  const options = mainEquipmentId
    ? searchMainEquipmentBasedEquipmentQuery.data ?? emptyArray
    : searchQuery.data ?? emptyArray

  const value = props.value?.equipment.text

  useEffect(() => {
    if (value) {
      setInputValue(value)
    }
  }, [value])

  return (
    <Autocomplete
      data-test-id="equipment-search"
      value={props.value ?? null}
      inputValue={inputValue}
      open={open && options.length > 0}
      onOpen={() => {
        setOpen(true)
      }}
      onChange={(event, next, reason) => {
        if (reason === 'clear') {
          setInputValue('')
          props.onChange(undefined)
          return
        }
        if (next && typeof next !== 'string') {
          props.onChange(next)
        }
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, newValue, reason) => {
        if (reason === 'input' && newValue !== inputValue) {
          setInputValue(newValue)

          if (!newValue && props.value) {
            props.onChange(undefined)
          }
        }
      }}
      options={options}
      getOptionLabel={(plain) => getEquipmentLabel(plain.equipment, false)}
      isOptionEqualToValue={areEquipmentsSame}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={!!props.errorText}
            data-test-id="equipment-search-input"
            helperText={props.errorText}
            label={
              t(getTranslationKey('shiftEvent.label.functionalLocation', props.translationPrefix)) +
              (!props.required
                ? ` (${t(getTranslationKey('shiftEvent.label.optional', props.translationPrefix))})`
                : '')
            }
            required={props.required}
            placeholder={t(getTranslationKey('pleaseStartTyping', props.translationPrefix))}
            variant="filled"
            InputProps={{...params.InputProps, ...INPUT_FORM_FIELD_SX}}
          />
        )
      }}
      PaperComponent={paper}
      forcePopupIcon={false}
      renderOption={(props, option) => <li {...props}>{getDisplayPath(option)}</li>}
      // per default the auto-compleat component will use the getOptionLabel,
      // compare it with the given input text and will filter out all that do not match.
      // that is a problem when having sapNumbers were we remove the leading zeros
      // 00000008321003 will be rendered as 8321003 and therefor not match if the input is 0083
      // and will not be presented as an option
      // to avoid this behavior, we override the filterOptions function to force it to compare on object level
      filterOptions={(options) => options}
      disabled={props.disabled}
    />
  )
}
