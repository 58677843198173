import {formatDateLocale} from '@hconnect/uikit'
import {
  formatTimeLocale,
  QuickSelection
} from '@hconnect/uikit/src/lib2/components/momentDatePicker'
import {QuickSelectionItem} from '@hconnect/uikit/src/lib2/components/momentDatePicker/types'
import {TFunction} from 'i18next'
import moment, {Moment} from 'moment-timezone'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {Shift} from '../../types'
import {
  roundUpToTheNearestMinute,
  getQuickSelectFilter,
  QuickSelectSlots,
  QUICK_SELECT_SLOTS_DEFAULT,
  getTranslationKey
} from '../../utils'

const getQuickSelectionItems = (
  quickSelectSlots: QuickSelectSlots[],
  shifts: Shift[],
  plantNow: Moment,
  t: TFunction,
  translationPrefix?: string
): QuickSelectionItem[] => {
  return quickSelectSlots.map((curr: QuickSelectSlots) => {
    const [start, end] = getQuickSelectFilter(curr, shifts, plantNow)
    return {
      id: curr,
      title: t(getTranslationKey(`shiftEvent.quickSelection.${curr}`, translationPrefix)),
      dates: [start, roundUpToTheNearestMinute(end)]
    }
  })
}

const getDateLabel = ({
  t,
  shiftStart,
  currentDate,
  language,
  translationPrefix
}: {
  t: TFunction
  shiftStart: Moment
  currentDate: Moment
  language: string
  translationPrefix?: string
}) => ({
  currentShift: () => t(getTranslationKey('shiftEvent.quickSelection.today', translationPrefix)),
  pastShift: () => {
    const isToday = shiftStart.isSame(currentDate, 'date')
    return isToday
      ? t(getTranslationKey('shiftEvent.quickSelection.today', translationPrefix))
      : t(getTranslationKey('shiftEvent.quickSelection.yesterday', translationPrefix))
  },
  today: () => t(getTranslationKey('shiftEvent.quickSelection.today', translationPrefix)),
  last24hours: () =>
    t(getTranslationKey('shiftEvent.quickSelection.last24hours', translationPrefix)),
  yesterday: () => t(getTranslationKey('shiftEvent.quickSelection.yesterday', translationPrefix)),
  lastWeekend: () => formatDateLocale(shiftStart, language),
  last3days: () => formatDateLocale(shiftStart, language),
  last7days: () => formatDateLocale(shiftStart, language),
  last30days: () => formatDateLocale(shiftStart, language),
  next30days: () => formatDateLocale(shiftStart, language),
  rolling12months: () => formatDateLocale(shiftStart, language)
})

type Props = {
  quickSelectId?: string
  onChange: (item: QuickSelectSlots) => void
  shifts: Shift[]
  timezone: string
  plantNow: Moment
  translationPrefix?: string
  quickSelectSlots?: QuickSelectSlots[]
}

export const QuickSelect: React.FC<Props> = ({
  quickSelectId,
  onChange,
  shifts,
  timezone,
  plantNow,
  translationPrefix,
  quickSelectSlots = QUICK_SELECT_SLOTS_DEFAULT
}) => {
  const {t, i18n} = useTranslation()
  const state = useMemo(() => {
    const options: QuickSelectionItem[] = getQuickSelectionItems(
      quickSelectSlots,
      shifts,
      plantNow,
      t,
      translationPrefix
    )

    const selectedItem = options?.filter((item) => {
      return item.id === quickSelectId
    })[0]

    const customTemplate = (option: QuickSelectionItem) => {
      const {id, dates} = option
      const [start, end] = dates
      const currentDate = moment.tz(timezone)

      const dateLabel = id
        ? getDateLabel({
            t,
            shiftStart: start,
            currentDate,
            language: i18n.language,
            translationPrefix
          })[id]()
        : t(getTranslationKey(`shiftEvent.quickSelection.${id}`, translationPrefix))
      return `${dateLabel} , ${formatTimeLocale(start)} - ${end && formatTimeLocale(end)}`
    }
    return {options, selectedItem, customTemplate}
  }, [
    i18n.language,
    quickSelectId,
    shifts,
    t,
    timezone,
    plantNow,
    translationPrefix,
    quickSelectSlots
  ])

  const {options, selectedItem, customTemplate} = state

  return (
    <QuickSelection
      handleItemClick={(_, quickSelectItem) => {
        onChange(quickSelectItem?.id as QuickSelectSlots)
      }}
      selectedItem={selectedItem}
      dateCustomTemplate={customTemplate}
      items={options}
      timezone={timezone}
    />
  )
}
