import {Dates, formatDateLocale} from '@hconnect/uikit'
import {
  Calendar,
  DatePickerTextField,
  PickerForm,
  TabBar
} from '@hconnect/uikit/src/lib2/components/momentDatePicker'
import {Button, Checkbox, Popover, Box, FormControlLabel} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useCallback, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {getResponsivePopoverStyle} from '../../styles'
import {ParticularShift, Shift} from '../../types'
import {QuickSelectSlots, getTranslationKey} from '../../utils'

import {QuickSelect} from './QuickSelect'
import {handleDateChange, ShiftDropdown, getShiftRange, momentShiftPickLabel} from './ShiftDropdown'

type DateRange = [Moment | null, Moment | null]

interface Props {
  className?: string
  date?: Moment | null
  dateRange?: DateRange
  quickSelectId?: string
  isOutsideRange?: (arg: Moment) => boolean
  label?: React.ReactNode
  onChange: (value) => void
  shifts: Shift[]
  timezone: string
  plantNow: Moment
  translationPrefix?: string
  quickSelectSlots?: QuickSelectSlots[]
}

enum TYPES {
  QUICK_SELECT = 'quickSelect',
  DATE = 'date',
  DATE_RANGE = 'dateRange'
}

const getSelectionType = (quickSelectId, date, dateRange) => {
  if (quickSelectId) return TYPES.QUICK_SELECT
  if (date) return TYPES.DATE
  if (dateRange) return TYPES.DATE_RANGE
  throw new Error('no value selected in date picker')
}

const DatePicker: React.FC<Props> = (props) => {
  const {
    quickSelectId,
    date,
    dateRange = [],
    isOutsideRange,
    onChange,
    className,
    shifts,
    timezone,
    plantNow,
    translationPrefix,
    quickSelectSlots
  } = props

  const {t, i18n} = useTranslation()
  const {language} = i18n
  const isSingleDate = date !== undefined
  const [startDate, endDate] = dateRange
  const [selectedStartDate, setSelectedStartDate] = useState<Moment | null>(
    date || startDate || null
  )
  const [selectedEndDate, setSelectedEndDate] = useState<Moment | null | undefined>(endDate)
  const [selectedTime, setSelectedTime] = useState<Dates>({
    startDate: date || startDate || null,
    endDate: endDate || null
  })
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [showCalendar, setShowCalendar] = useState(!quickSelectId)
  const [isRangeSelect, setShowRangeSelect] = useState(!isSingleDate)
  const [selectionType, setType] = useState(getSelectionType(quickSelectId, date, dateRange))
  const noDateText = t(getTranslationKey('datePicker.chooseDate', translationPrefix))

  const selectionText = useMemo(() => {
    if (selectionType === TYPES.QUICK_SELECT) {
      return t(getTranslationKey(`shiftEvent.quickSelection.${quickSelectId}`, translationPrefix))
    }
    if (selectionType === TYPES.DATE || !selectedEndDate) {
      return momentShiftPickLabel(selectedStartDate as Moment, {shifts})
    }
    if (selectionType === TYPES.DATE_RANGE) {
      return `${formatDateLocale(selectedStartDate as Moment, language)} - ${formatDateLocale(
        selectedEndDate,
        language
      )}`
    }
    return noDateText
  }, [
    selectionType,
    selectedEndDate,
    noDateText,
    t,
    quickSelectId,
    selectedStartDate,
    shifts,
    language,
    translationPrefix
  ])

  const close = useCallback(() => setOpen(false), [setOpen])

  const handleDateRangeChange = (dates: Dates, withTime?: boolean) => {
    const {startDate, endDate} = dates
    if (withTime) {
      setSelectedTime({
        startDate: startDate,
        endDate: endDate
      })
    }

    const newDates: Dates = {
      startDate:
        selectedTime.startDate && startDate && !withTime
          ? startDate
              .clone()
              .hours(selectedTime.startDate.hours())
              .minutes(selectedTime.startDate.minutes())
              .seconds(selectedTime.startDate.seconds())
              .milliseconds(selectedTime.startDate.milliseconds())
          : startDate,
      endDate:
        selectedTime.endDate && endDate && !withTime
          ? endDate
              .clone()
              .hours(selectedTime.endDate.hours())
              .minutes(selectedTime.endDate.minutes())
              .seconds(selectedTime.endDate.seconds())
              .milliseconds(selectedTime.endDate.milliseconds())
          : endDate
    }

    setSelectedStartDate(startDate)
    setSelectedEndDate(endDate)

    if (newDates.endDate) {
      onChange(newDates)
      setType(TYPES.DATE_RANGE)
    }
  }

  const handleSingleDateChange = (date: Moment | null) => {
    if (!date) return
    const updatedDate = getShiftRange(handleDateChange(date, {shifts}))
    setSelectedStartDate(updatedDate.startDate)
    setSelectedEndDate(updatedDate.startDate)
    onChange(updatedDate)
    setType(TYPES.DATE)
  }

  const calendarProps = {
    timezone,
    isOutsideRange,
    startDate: selectedStartDate,
    ...(isRangeSelect
      ? {endDate: selectedEndDate, onRangeChange: handleDateRangeChange}
      : {onDateChange: handleSingleDateChange})
  }

  return (
    <Box
      sx={{
        padding: 0,
        display: 'inline-block',
        position: 'relative',
        zIndex: 999,
        width: {
          xs: '100%',
          md: 'inherit'
        }
      }}
      className={className}
    >
      <DatePickerTextField
        buttonRef={anchorRef}
        focus={open}
        setFocus={setOpen}
        text={selectionText}
        label={t(getTranslationKey('datePicker.label', translationPrefix))}
      />

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          sx: getResponsivePopoverStyle,
          id: 'simple-date-picker-popover-paper'
        }}
        data-test-id="simple-date-picker-popover"
      >
        <Box
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 356
          }}
        >
          <Box pb={1}>
            <TabBar setShowCalendar={setShowCalendar} showCalendar={showCalendar} />
          </Box>

          {showCalendar ? (
            <>
              <Calendar {...calendarProps} />
              <Box
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                  width: '100%'
                })}
              >
                <FormControlLabel
                  sx={{mb: 1}}
                  control={
                    <Checkbox
                      data-test-id="range-select"
                      checked={isRangeSelect}
                      onChange={() => setShowRangeSelect((prev) => !prev)}
                    />
                  }
                  label={t(getTranslationKey('datePicker.selectRange', translationPrefix))}
                />
                {isRangeSelect ? (
                  <PickerForm
                    applyDateRange={(value: Dates) => {
                      handleDateRangeChange(value, true)
                      close()
                    }}
                    isOutsideRange={isOutsideRange}
                    isSingleDate={!isRangeSelect}
                    endDate={selectedEndDate}
                    startDate={selectedStartDate}
                    timeZone={timezone}
                  />
                ) : (
                  <>
                    <ShiftDropdown
                      date={selectedStartDate}
                      shifts={shifts}
                      onChange={(particularShift: ParticularShift) => {
                        const range = getShiftRange(particularShift)
                        onChange(range)
                        setSelectedStartDate(range.startDate)
                        setType(TYPES.DATE)
                      }}
                      label={t(getTranslationKey('datePicker.label', translationPrefix))}
                      translationPrefix={translationPrefix}
                    />
                    <Box display={'flex'} justifyContent={'center'} pt={2}>
                      <Button
                        variant={'contained'}
                        onClick={() => setOpen(false)}
                        data-test-id="single-day-apply"
                      >
                        {t(getTranslationKey('datePicker.apply', translationPrefix))}
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </>
          ) : (
            <QuickSelect
              shifts={shifts}
              plantNow={plantNow}
              timezone={timezone}
              onChange={(val: QuickSelectSlots) => {
                onChange(val)
                setType(TYPES.QUICK_SELECT)
                close()
              }}
              quickSelectId={quickSelectId}
              translationPrefix={translationPrefix}
              quickSelectSlots={quickSelectSlots}
            />
          )}
        </Box>
      </Popover>
    </Box>
  )
}

export {DatePicker}
