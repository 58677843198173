import {Theme} from '@mui/material'

export const getResponsivePopoverStyle = (theme: Theme) => ({
  marginTop: 2,
  color: theme.palette.primary.contrastText,
  '@media only screen and (max-width: 410px)': {
    display: 'flex',
    justifyContent: 'center',
    left: '0 !important',
    width: '100%',
    maxWidth: '100%'
  }
})
